#container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  min-height: 100vh;
}

#page-content {
  flex-grow: 1;
  margin-left: 1rem;
  margin-right: 1rem;
}

#navbar {
  width: 200px;
  background-color: white;
  border-right: 1px solid lightgray;
}

#navbar-header {
  padding: 10px 20px 0 20px;
  margin: 0;
  font-weight: bold;
  text-align: center;
}

#navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#navbar ul li {
  display: flex;
  background-color: #f48120;
  flex-direction: column;
  border-bottom: 1px solid black;
}

#navbar ul li:hover {
  background-color: #faad3f;
}

#navbar ul li a {
  text-decoration: none;
  padding: 20px 10px;
  margin: 0;

  color: black;
  width: 100%;
  flex-grow: 1;
}

.last-updated {
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: bold;
  font-size: xx-large;
}

.nav-active {
  font-weight: bold;
}

#idiot-guide {
  margin: 0;
  padding: 20px 10px;
  font-size: small;
  text-align: center;
  border-bottom: 1px solid black;
}
