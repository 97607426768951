.domain-name-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 8px;
}

.domain-name-container img {
  width: 16px;
}

table {
  border-collapse: collapse;
}

th,
td {
  padding: 10px 5px;
  text-align: left;
}

table,
th,
td {
  border: 1px solid black;
}

#table-container {
  padding: 10px 10px;
}
